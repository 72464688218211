import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import ServiceSection from "~sections/LandingPages/Connections/Service"
import ContentOne from '~sections/project/ContentOne'
import ContentTwo from '~sections/project/ContentTwo'
import ContentThree from '~sections/project/ContentThree'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import HeroSection from "~sections/LandingPages/Autopilot/Hero/HeroSection"
import Testimonial from "~sections/customer-stories/Testimonial"
import { Helmet } from "react-helmet"
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Autopilot() {
  return (
    <>
    <Helmet>
    <title>Smart Sequences make your life easier.</title>
    <meta name="description" content="Smart sequences make your life easier. Schedule timed messages, triggered messages, and specific actions to move a lead through the sales process on autopilot."/>
    <meta property="og:title" content="We-Connect | Smart Sequences make your life easier."/>
    <meta property="og:description" content="Smart sequences make your life easier. Schedule timed messages, triggered messages, and specific actions to move a lead through the sales process on autopilot.  "/>
    <meta property="og:image" content="https://we-connect.io/we-connect_generate-leads-on-autopilot-page.png"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/generate-leads-on-autopilot"/>
    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="We-Connect | Smart Sequences make your life easier."/>
    <meta property="twitter:description" content="Smart sequences make your life easier. Schedule timed messages, triggered messages, and specific actions to move a lead through the sales process on autopilot.  "/>
    <meta property="twitter:image" content="https://we-connect.io/we-connect_generate-leads-on-autopilot-page.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <HeroSection/>
      <ServiceSection/>
      <ContentOne/>
      <ContentTwo/>
      <ContentThree/>
      <Testimonial />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
